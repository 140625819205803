import React, { useEffect } from "react"
import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import Hero from "@components/PostIdPage/Hero"
import BodyPost from "@components/PostIdPage/BodyPost"
import { graphql } from "gatsby"
import { constants } from "@constants/constants"
import "@styles/Components/post.scss"
import RiskAssessment from "@components/global/RiskAssessment"
import Footer from "@components/global/Footer"

const { SANITY_PROJECTID, SANITY_DATASET, SANITY_TOKEN } = constants

const incrementCount = id => {
  const mutations = [
    {
      patch: {
        id,
        setIfMissing: {
          viewCount: 0,
        },
        inc: {
          viewCount: 1,
        },
      },
    },
  ]
  fetch(
    `https://${SANITY_PROJECTID}.api.sanity.io/v1/data/mutate/${SANITY_DATASET}`,
    {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${SANITY_TOKEN}`,
      },
      body: JSON.stringify({ mutations }),
    }
  )
    .then(response => response.json())
    .catch(err => err)
}

const PostBySlugPage = ({ data: { sanityPost } }) => {
  useEffect(() => {
    const viewedPost = localStorage.getItem(`post${sanityPost.id}`)
    if (!viewedPost) {
      incrementCount(sanityPost._id)
      localStorage.setItem(`post${sanityPost.id}`, "true")
    }
  })

  const meta = sanityPost.seo
    ? Object.entries(sanityPost.seo).map(([key, value]) => {
        return { name: key, content: value }
      })
    : []

  return (
    <>
      <SEO meta={meta} seo={sanityPost.seo} cardImage={sanityPost.customImage?.image.asset.gatsbyImageData.images.fallback.src}/>
      <CorporativeHeader />
      <Hero post={sanityPost} />
      <BodyPost _rawBody={sanityPost._rawBody} slug={sanityPost.slug.current} />
      <RiskAssessment />
      <Footer />
    </>
  )
}
export default PostBySlugPage

export const query = graphql`
  query PostQuery($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      id
      _id
      title
      slug {
        current
      }
      publishedAt
      customImage {
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
            description
          }
        }
      }
      body {
        children {
          text
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        name
      }
      categories {
        title
      }
      seo {
        title
        description
        keywords
      }
    }
  }
`
