import React from 'react'
import {FaFacebookF, FaTwitter, FaLinkedinIn} from "react-icons/fa"

export default function SocialWidget({slug}) {
  return (
    <div className="social-widget">
      <a href={`http://www.facebook.com/sharer.php?u=https://climatecheck.com/blog/${slug}/`} target="_blank" rel="noopener noreferrer" className="social-icon facebook">
        <FaFacebookF  />
      </a>
      <a href={`http://twitter.com/share?url=https://climatecheck.com/blog/${slug}/`} target="_blank" rel="noopener noreferrer" className="social-icon twitter">
        <FaTwitter  />
      </a>
      <a href={`http://www.linkedin.com/shareArticle?mini=true&url=https://climatecheck.com/blog/${slug}/`} target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
        <FaLinkedinIn  />
      </a>
    </div>
  )
}
