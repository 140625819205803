import React from "react"
import { BaseBlockContent } from "@utils/richText"
import { BaseBlockContent2 } from "@utils/richTextS"
import SocialWidget from "../SocialWidget"

interface BodyPostProps {
  _rawBody: any
  slug: string
}

const BodyPost = ({ _rawBody, slug }: BodyPostProps) => {
  return (
    <div className="blog-body-container">
      <div className="body-post-container">
        <div className="post-content">
        <BaseBlockContent2 className="post-content" blocks={_rawBody} />
        </div>
        <SocialWidget slug={slug} />
      </div>
    </div>
  )
}

export default BodyPost
