import React, { useEffect, useState } from "react"
import BackgroundImage from "gatsby-background-image"
import readingTime from "reading-time"
import { constants } from "@constants/constants"
import { getImage } from "gatsby-plugin-image"
import { convertSingleBgImage, convertToBgImage } from "gbimage-bridge"
const { SANITY_PROJECTID, SANITY_DATASET } = constants

interface IBody {
  children: {
    text: string
  }[]
}

interface IPost {
  author: {
    name: String
  }
  id: string
  _id: string
  timeToRead: number
  title: string
  customImage: {
    image:{
      asset: {
        fluid
      }
    }
  }
  body: IBody[]
  categories: {
    title: string
  }[]
}

interface HeroProps {
  post: IPost
}
 
const Hero = ({ post }: HeroProps) => {
  const [viewCount, setViewCount] = useState(0)
  const { _id, author, title, customImage, categories, body, publishedAt } = post
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://${SANITY_PROJECTID}.api.sanity.io/v1/data/query/${SANITY_DATASET}?query=*[_id==$id]{viewCount}&$id="${_id}"`
      ).then(res => res.json())
      let count = res.result[0].viewCount
      if (count) setViewCount(count)
    }
    fetchData()
  }, [])
  let totalText = ""
  for (const p of body) {
    totalText += p.children[0]?.text
  }
  const timeToRead = Math.ceil(readingTime(totalText).minutes)

  const getDate = (date) => {
    const newDate = new Date(date)
    const americanDate = new Intl.DateTimeFormat("en-US", {month: "short", day: "2-digit", year: "numeric"}).format(newDate)

    return americanDate
  }

  const getCategoryColor = (category) => {
    switch (category) {
      case 'Heat':
        return '#FF6E05'
        break;
      case 'Storm':
        return '#9295E2'
        break;
      case 'Drought':
        return '#CAA65F'
        break;
      case 'Fire':
        return '#FFAA2C'
        break;
      case 'Flood':
        return '#96CDFF'
        break;
    
      default:
        return '#242424'
        break;
    }
  }

  const image = convertToBgImage(getImage(customImage.image.asset?.gatsbyImageData))
  return (
    <BackgroundImage
      Tag="div"
      className="hero-post-container"
      {...image} 
      alt={customImage.image.asset?.description} 
    >
      <div className="hero-blog-content">
        <span className="our-blog">{getDate(publishedAt)}</span>
        <h1 className="headline">{title}</h1>
        <div className="blog-description">
          <span style={{color: getCategoryColor(categories[0]?.title), textTransform: 'uppercase'}}>{categories[0] ? categories[0]?.title : null}</span>{" | "}<span>by {author.name}</span>{" | "}<span>{timeToRead} Minutes</span>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
